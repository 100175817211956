const { createContext, useState, useContext, useEffect } = require("react");

const DrawerContext = createContext()

const DrawerProvider = ({ children }) => {

    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [items, setItems] = useState([])

    useEffect(() => {
        document.title = title ? `${title} - My Community` : `My Community`
    }, [title])

    return (
        <DrawerContext.Provider value={{ open, setOpen, title, setTitle, items, setItems }}>
            {children}
        </DrawerContext.Provider>
    )
}

const useDrawer = () => {
    const { open, setOpen, title, setTitle, items, setItems } = useContext(DrawerContext)

    return {
        open,
        setOpen,
        title,
        setTitle,
        items,
        setItems
    }
}

export { DrawerProvider, useDrawer }