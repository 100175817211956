const BASE_URL = process.env.NODE_ENV === 'production' ? "https://myc-service.sysbrew.in/community" : "https://myc-service.sysbrew.in/community"
// const BASE_URL = process.env.NODE_ENV === 'production' ? "https://myc-service.sysbrew.in/community" : "http://localhost:8088/community"

export const URLS = {
    LOGIN: `${BASE_URL}/admin/login`,
    AUTHENTICATE_TOKEN: `${BASE_URL}/admin/token`,
    FILE_URL: `${BASE_URL}/file`,
    INTERNAL_FILE_URL: `${BASE_URL}/internal/file/{type}`,
    REGISTER: `${BASE_URL}/register`,
    BUSINESS_INDUSTRIES: `${BASE_URL}/business/industries`,
    EVENTS: `${BASE_URL}/events`,
    EVENT_DETAIL: `${BASE_URL}/event/{id}`,
    EVENT: `${BASE_URL}/event`,
    POLLS: `${BASE_URL}/admin/polls`,
    NOTIFICATIONS: `${BASE_URL}/admin/notifications`,
    ANNOUNCEMENTS: `${BASE_URL}/admin/announcements`,
    GENERAL_ANNOUNCEMENT: `${BASE_URL}/announcement/general`,
    ANNOUNCEMENT: `${BASE_URL}/admin/announcement`,
    ANNOUNCEMENT_ID: `${BASE_URL}/admin/announcement/{id}`,
    ADMIN_POLL_ID: `${BASE_URL}/admin/poll/{id}`,
    POLL_DETAIL: `${BASE_URL}/poll/{id}`,
    POLL: `${BASE_URL}/poll`,
    AD: `${BASE_URL}/ad`,
    ADS: `${BASE_URL}/ads`,
    MEMBERS: `${BASE_URL}/members`,
    MEMBERS_REPORT: `${BASE_URL}/admin/members/report`,
    BUSINESS_REPORT: `${BASE_URL}/admin/business/report`,
    MEMBER_DETAIL: `${BASE_URL}/member/{id}/details`,
    UPDATE_STATUS: `${BASE_URL}/change/status`,
    MEMBER_SUBSCRIPTIONS: `${BASE_URL}/member/subscriptions/{id}`,
    PENDING_CHANGES: `${BASE_URL}/pending/changes`,
    MEMBER_PENDING_CHANGES: `${BASE_URL}/member/{id}/pending/changes`,
    UPDATE_CHANGES_STATUS: `${BASE_URL}/draft/change/status`,
    MEMBER_SUBSCRIPTIONS_LIST: `${BASE_URL}/member/subscriptions/list`,
    MASTER_SUBSCRIPTIONS_LIST: `${BASE_URL}/master/subscriptions`,
    MASTER_DONATIONS_LIST: `${BASE_URL}/master/donations`,
    FETCH_MEMBERS: `${BASE_URL}/members/list`,
    FETCH_MEMBERS_OPEN: `${BASE_URL}/open/members/list`,
    SAVE_MEMBER_SUBSCRIPTION: `${BASE_URL}/member/subscription`,
    SAVE_SUBSCRIPTION: `${BASE_URL}/subscription`,
    SAVE_DONATION: `${BASE_URL}/donation`,
    SUBSCRIPTION_URL: `${BASE_URL}/subscription/{id}`,
    MARK_SUBSCRIPTION_RECEIVED_URL: `${BASE_URL}/member/subscription/received`,
    DONATION_URL: `${BASE_URL}/donation/{id}`,
    LIST_ADMINS: `${BASE_URL}/admin/list`,
    ADD_ADMIN_URL: `${BASE_URL}/admin/add`,
    ADMIN_SEND_NOTIFICATION: `${BASE_URL}/admin/notification`,
    ADMIN_URL: `${BASE_URL}/admin/{id}`,
    LIST_COMMITTEE: `${BASE_URL}/admin/committee`,
    COMMITTEE_URL: `${BASE_URL}/admin/committee/{id}`,
    COMMITTEE_ADD_URL: `${BASE_URL}/admin/committee/add`,
    MEMBER_DONATIONS_LIST: `${BASE_URL}/member/donations`,
    SAVE_MEMBER_DONATION: `${BASE_URL}/member/donation`,
    SUBSCRIPTION_REPORT: `${BASE_URL}/member/subscriptions/report`,
    DONATION_REPORT: `${BASE_URL}/member/donations/report`,
    BIRTHDAY_LIST: `${BASE_URL}/admin/dashboard/birthday`,
    ANNIVERSARY_LIST: `${BASE_URL}/admin/dashboard/anniversary`,
    COMMUNITY_URL: `${BASE_URL}/admin/community`,
    ADD_MEMBER: `${BASE_URL}/admin/member`,
    ADD_STAFF: `${BASE_URL}/admin/staff`,
}