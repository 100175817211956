import { useModal } from "./index"
const { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } = require("@mui/material")

const Modal = () => {
    const { modal: { title, message, show, leftButton, leftButtonAction, rightButton, rightButtonAction }, hideModal } = useModal()
    return (
        <Dialog
            open={show}
            maxWidth="sm"
            fullWidth
            onClose={hideModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {leftButton ? <Button onClick={leftButtonAction}>{leftButton}</Button> : null}
                <Button onClick={rightButtonAction} autoFocus>
                    {rightButton}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export { Modal }