import React, { Suspense, lazy, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useAuth } from "Auth"
import { Loading } from "./Loading"
import { Modal } from 'Modals/Modal'
import { useAppContext } from './AppContext';
import { Alert, Snackbar, Stack } from '@mui/material';
import { useSnackbar } from 'Components/Snackbar';
import { useState } from 'react';
import { Close } from '@mui/icons-material';
import RestService from 'Services/RestService';
import { URLS } from 'Common/urls';
const Authenticated = lazy(() => import("./Authenticated").then(module => ({ default: module.Authenticated })))
const UnAuthenticated = lazy(() => import("./UnAuthenticated").then(module => ({ default: module.UnAuthenticated })))

function Copyright() {
  return (
    <React.Fragment>
      <Typography variant="body2" color="text.secondary" align="center" style={{ marginTop: 100 }}>
        {'Copyright © '}
        <Link color="inherit" href="http://sysbrew.in" style={{ textDecoration: 'none' }}>
          MyCommunity @ SysBrew
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="overline" component="p" color="text.secondary" align="center">
        Version: {global.appVersion}
      </Typography>
    </React.Fragment>
  );
}

const App = () => {
  const { isAuthenticated, isLoading } = useAuth()
  const { isLoading: isGeneralLoading } = useAppContext()
  const { snackbar, closeSnackbar, autoHideDuration } = useSnackbar()
  const [announcement, setAnnouncement] = useState(null)
  const [showAnnouncement, setShowAnnouncement] = useState(false)

  useEffect(() => {
    RestService.get(URLS.GENERAL_ANNOUNCEMENT)
      .then(data => {
        if (data?.data?.announcement?.description) {
          setAnnouncement(data.data.announcement.description);
          setShowAnnouncement(true)
        }
      })
      .catch(error => console.log(error))
  }, [])

  const onClose = () => setShowAnnouncement(false)

  if (isLoading) return <Loading />

  return (
    <Container maxWidth={false} disableGutters>
      {showAnnouncement ? (
        <div
          style={{ padding: 4, backgroundColor: 'rgb(124,45,18)', paddingLeft: 16, paddingRight: 16, color: 'white' }}
        >
          <Stack direction='row' justifyContent='space-between'>
            <div>{announcement}</div>
            <Close onClick={onClose} />
          </Stack>
        </div>
      ) : null}
      <Box sx={{ mb: 4 }}>
        <Suspense fallback={<Loading />}>{isAuthenticated ? <Authenticated /> : <UnAuthenticated />}</Suspense>
        <Copyright />
      </Box>
      <Modal />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={autoHideDuration}
        onClose={closeSnackbar}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
        <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isGeneralLoading ? <Loading /> : null}
    </Container>
  )
}

export { App }
