import { createContext, useContext, useState } from "react";
import { SysBrewAuthProvider, useSysBrewAuth } from "SysBrewAuthProvider";

const AUTH_TOKEN = "auth_token"
const UserContext = createContext()

const getToken = () => localStorage.getItem(AUTH_TOKEN)
const removeToken = () => localStorage.removeItem(AUTH_TOKEN)

const saveCommunityForSessionValue = community => {
    localStorage.setItem("communityForSession", JSON.stringify(community))
    return community
}
const getCommunityForSessionValue = () => {
    return JSON.parse(localStorage.getItem("communityForSession") || "{}")
}

const removeCommunityForSessionValue = () => {
    const value = getCommunityForSessionValue()
    localStorage.removeItem("communityForSession")
    return value
}

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({})
    const [communityForSession, setCommunityForSession] = useState(getCommunityForSessionValue())

    return (
        <SysBrewAuthProvider>
            <UserContext.Provider value={{
                user,
                setUser,
                communityForSession,
                setCommunityForSession,
            }}>{children}</UserContext.Provider>
        </SysBrewAuthProvider>
    )
}

const throwError = hook => {
    throw new Error(`${hook} must be used within a AuthProvider`)
}

const useAuth = () => {
    const {
        user,
        retry,
        setCommunityForSession,
        communityForSession,
    } = useContext(UserContext)
    const { user: authUser, isLoading, error, isAuthenticated, logout: authLogout, token } = useSysBrewAuth()

    if (user === undefined) throwError("useAuth")

    const clearSession = () => {
        removeToken()
        removeCommunityForSessionValue()
    }

    const logout = () => {
        clearSession()
        authLogout({ returnTo: window.location.origin })
    }

    const setCommunityForSessionWrapper = ({ communityCode }) => {
        saveCommunityForSessionValue(communityCode)
        setCommunityForSession(communityCode)
    }

    return {
        isLoading,
        error,
        isAuthenticated,
        logout,
        clearSession,
        getAccessToken: token,
        communityForSession,
        setCommunityForSession: setCommunityForSessionWrapper,
        retry,
        user: authUser
    }
}

export { AuthProvider, useAuth, getToken }