const { createContext, useState, useContext } = require("react")

const ModalContext = createContext()

const ModalProvider = ({ children }) => {
    const [modal, setModal] = useState({ show: false, title: '', message: '', leftButton: undefined, rightButton: undefined, leftButtonAction: () => { }, rightButtonAction: () => { } })
    const hideModal = () => {
        setModal({ show: false, title: '', message: '', leftButton: undefined, rightButton: undefined, leftButtonAction: () => { }, rightButtonAction: () => { } })
    }

    const showModal = ({ title, message, leftButton, leftButtonAction, rightButton, rightButtonAction }) => {
        setModal({
            show: true,
            title,
            message,
            leftButton,
            rightButton,
            leftButtonAction: leftButtonAction || (() => { hideModal() }),
            rightButtonAction: rightButtonAction || (() => { hideModal() })
        })
    }
    return (
        <ModalContext.Provider value={{
            modal,
            hideModal,
            showModal,
        }}>
            {children}
        </ModalContext.Provider>
    )
}

const useModal = () => {
    const { modal, hideModal, showModal } = useContext(ModalContext)

    return {
        modal, hideModal, showModal
    }
}

export { ModalProvider, useModal }